<template>
  <section class="section">
    <div>
      <h4 class="title has-text-centered is-4">Item Form</h4>

      <form @submit="handleSubmit">
        <div class="box">
          <h5 class="title is-5">General</h5>
          <div class="columns">
            <div class="column is-3">
              <b-field label="ID" custom-class="is-small">
                <b-input v-model="formData.id" size="is-small"></b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Name" custom-class="is-small">
                <b-input v-model="formData.name" size="is-small"></b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Generator" custom-class="is-small">
                <b-select v-model="formData.generator_id" size="is-small">
                  <option></option>
                  <option v-for="generator in generators" :key="generator.id" :value="generator.id">{{ generator.name }}</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Generator Level" custom-class="is-small">
                <b-input v-model="formData.generator_level" size="is-small"></b-input>
              </b-field>
            </div>
          </div>

          <h5 class="title is-5">Recipes</h5>

          <b-button type="is-info" icon-left="plus" size="is-small" @click="addRecipe()">Add recipe</b-button>

          <div v-for="recipe in formData.recipes" :key="recipe.id" class="mt-4">
            <b-field grouped>
              <b-field label="Item 1" custom-class="is-small">
                <b-autocomplete
                  v-model="recipe.parent1_id"
                  size="is-small"
                  ref="autocomplete"
                  :data="filteredItemsArray(recipe.parent1_id)"
                  :select-on-click-outside="true"
                  placeholder="First item"
                  field="id"
                  keep-first
                  @select="option => selected = option">
                  <template #empty>No results for {{ recipe.parent1_id }}</template>
                </b-autocomplete>
              </b-field>
              <b-field label="Item 2" custom-class="is-small">
                <b-autocomplete
                  v-model="recipe.parent2_id"
                  size="is-small"
                  ref="autocomplete"
                  :data="filteredItemsArray(recipe.parent2_id)"
                  :select-on-click-outside="true"
                  placeholder="Second item"
                  field="id"
                  keep-first
                  @select="option => selected = option">
                  <template #empty>No results for {{ recipe.parent2_id }}</template>
                </b-autocomplete>
              </b-field>
            </b-field>
          </div>

        </div>

        <b-button type="is-success" native-type="submit">{{ item ? 'Update' : 'Create' }}</b-button>

      </form>
    </div>

    <h4 class="title has-text-centered is-4">Crafting</h4>
    <b-table
      :data="items"
      :striped="true"
      :hoverable="true"
      :loading="loadingItems || loadingGenerators"
      class="mt-4 clickable-table user-table"
      @click="editCraftingItem"
    >
      <b-table-column field="id" label="ID" v-slot="props" centered>
        <img :src="itemImageSource(props.row.id)" class="furniture-image" />
        <br />
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="name" label="Name" v-slot="props">
        <p><strong>{{ props.row.name }}</strong></p>
      </b-table-column>

      <b-table-column field="energy_required" label="Energy Required" v-slot="props">
        <p><strong>{{ props.row.energy_required }}</strong></p>
      </b-table-column>

      <b-table-column field="generator_id" label="Generator" v-slot="props">
        <div style="text-align: center;">
          <img v-if="props.row.generator_id" :src="generatorImageSource(props.row.generator_id)" class="furniture-image" />
          <div v-if="props.row.generator_id">
            {{ props.row.generator_id }} Lvl. {{ props.row.generator_level }}
          </div>
        </div>
      </b-table-column>

      <b-table-column field="recipes" label="Recipes" v-slot="props">
        <div style="text-align: center;">
          <table v-if="props.row.recipes.length">
            <tr v-for="recipe in props.row.recipes" :key="recipe.id">
              <td>
                <img :src="itemImageSource(recipe.parent1_id)" class="furniture-image" />
                <br />
                {{ recipe.parent1_id }}
              </td>
              <td>
                <img :src="itemImageSource(recipe.parent2_id)" class="furniture-image" />
                <br />
                {{ recipe.parent2_id }}
              </td>
            </tr>
          </table>
        </div>
      </b-table-column>

    </b-table>

  </section>
</template>

<script>
import { mapState } from 'vuex';
import { getCraftingImageUrl } from '../../services/encyclopedia';
import { Crafting } from '../../services/api';

export default {
  name: 'Crafting',
  computed: {
    ...mapState('crafting', ['loadingGenerators', 'loadingItems', 'generators', 'items']),

  },
  data() {
    return {
      item: null,
      errors: [],
      loadingForm: false,
      sent: false,
      formData: {
        id: null,
        name: null,
        generator_id: null,
        generator_level: null,
        recipes: [],
      },
    };
  },
  mounted() {
    this.loadGenerators();
    this.loadItems();
  },
  methods: {
    filteredItemsArray(item) {
      return typeof item !== 'string' || !this.items
        ? []
        : this.items.filter((option) => option.id.toString().toLowerCase().indexOf(item.toLowerCase()) >= 0
          || option.name.toString().toLowerCase().indexOf(item.toLowerCase()) >= 0);
    },
    loadGenerators() {
      this.$store.dispatch('crafting/loadGenerators');
    },
    loadItems() {
      this.$store.dispatch('crafting/loadItems');
    },
    addRecipe() {
      this.formData.recipes.push({
        id: Math.random(0, 4000),
        parent1_id: null,
        parent2_id: null,
      });
    },
    editCraftingItem(row) {
      this.item = row;
      this.formData = {
        ...this.item,
      };
    },
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const action = this.item ? Crafting.updateItem(this.item.id, this.formData) : Crafting.addItem(this.formData);

      action
        .then(() => {
          this.sent = true;

          this.item = null;
          this.formData = {
            id: null,
            name: null,
            generator_id: null,
            generator_level: null,
            recipes: [],
          };

          this.loadItems();
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    generatorImageSource(id) {
      return getCraftingImageUrl('generators', id);
    },
    itemImageSource(id) {
      return getCraftingImageUrl('items', id);
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.furniture-image {
  max-height: 40px;
  max-width: 40px;
}
</style>
